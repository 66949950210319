export const groupBySize = (array, groupSize) => {
  return array
    .map((item, index) => {
      return index % groupSize === 0 ? array.slice(index, index + groupSize) : null;
    })
    .filter(function (item) {
      return item;
    });
};

export const openNewTab = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};

export const generateId = (length) => {
  var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
};
