import * as React from 'react';

import { Container, TitleContainer, Title, Desc } from './styles';

import { Logo, MenuButton } from '../../HomeHeader/styles';

import ArtQualities from '../ArtQualities';

const WhyArtMatter = (props) => {
  return (
    <Container>
      <Logo visible white />
      <MenuButton visible menuIsShown={props.menuIsShown} white />
      <TitleContainer>
        <Title>
          Why Art <br /> Qualities Matter?
        </Title>
        <Desc>
          Arts Qualities are not restricted to arts. <br />
          They can be applied to any discipline, from governance to business, from education to
          wellbeing. While they are not a solution in themselves, they may indeed be transformative
          qualities, currently undervalued, which — if included and intregrated — could make a vital
          difference to transcending our boundaries and overcoming even our largest challanges.
        </Desc>
      </TitleContainer>
      <ArtQualities />
    </Container>
  );
};

export default WhyArtMatter;
