import styled from 'styled-components';
import { BREAKPOINTS, COLORS, HudgeTitle, Pb } from '../../../utils/theme';

import PinkTexture1 from './pink-textures-1.png';
import PinkTexture2 from './pink-textures-2.png';

export const Container = styled.div`
  background-color: ${COLORS.BLACK};
  padding: 200px 56px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    background-image: url(${PinkTexture1});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    position: absolute;
    width: 180px;
    height: 255px;
    left: 0;
    top: -110px;

    @media (max-width: ${BREAKPOINTS.MOBILE}px) {
      left: -50px;
    }
  }
  &:after {
    content: '';
    background-image: url(${PinkTexture2});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    position: absolute;
    width: 250px;
    height: 200px;
    top: 0;
    right: 0;

    @media (max-width: ${BREAKPOINTS.MOBILE}px) {
      right: -100px;
    }
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    padding: 130px 0 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: inherit;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    flex-direction: column;
  }
`;

export const Title = styled(HudgeTitle)`
  color: ${COLORS.WHITE};
  max-width: 685px;

  br {
    display: none;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: -0.44px;
    padding: 0 20px;
    z-index: 1;

    br {
      display: block;
    }
  }
`;

export const Desc = styled(Pb)`
  color: ${COLORS.WHITE};
  max-width: 640px;
  padding: 100px 40px 0;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    padding: 24px 30px 0 20px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.2px;
  }
`;
