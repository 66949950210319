import * as React from 'react';

import { Background } from './styles';

const BackgroundComponent = ({ color, children, paddingTop }) => {
  return (
    <Background color={color} paddingTop={paddingTop}>
      {children}
    </Background>
  );
};

export default BackgroundComponent;
