import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';

import { COLORS } from '../../../utils/theme';
import useMousePosition from '../../../lib/useMousePosition';

import {
  Container,
  Title,
  ArtQuality as StyledArtQuality,
  ArtQualityImg,
  Type,
  Desc,
  ArtQualityMobileSelected,
  ArtQualityMobileBackground,
  ArtQualityMobileCategories,
  ArtQualityTag,
  MobileTagContainer,
  MobileTag,
  Tag,
} from './styles';

import { contentFetch } from '../../../lib/content-fetcher';

import { ART_QUALITIES_STYLES } from './constants';

const ArtQualitityMobile = ({ artQualities }) => {
  const [artQualitySelectedIdx, setArtQualitySelectedIdx] = useState(0);

  return (
    <>
      <ArtQualityMobileSelected
        href={
          artQualities[artQualitySelectedIdx].link ? artQualities[artQualitySelectedIdx].link : null
        }
        as={artQualities[artQualitySelectedIdx].link ? 'a' : 'div'}
      >
        <ArtQualityMobileBackground />
        <ArtQualityMobileBackground />
        <ArtQualityMobileBackground />
        <ArtQualityMobileBackground />
        <Type>{artQualities[artQualitySelectedIdx].name}</Type>
        <Desc maxWidth={ART_QUALITIES_STYLES[artQualitySelectedIdx]?.maxWidthDesc}>
          {artQualities?.[artQualitySelectedIdx]?.description}
        </Desc>
        <MobileTagContainer>
          <MobileTag isLink={!!artQualities[artQualitySelectedIdx].link}>
            {artQualities[artQualitySelectedIdx].link ? 'Click to explore' : 'Coming soon'}
          </MobileTag>
        </MobileTagContainer>
      </ArtQualityMobileSelected>
      <ArtQualityMobileCategories>
        {artQualities.map((artQuality, idx) => (
          <ArtQualityTag
            selected={artQualitySelectedIdx === idx}
            onClick={() => setArtQualitySelectedIdx(idx)}
          >
            {artQuality.name}
          </ArtQualityTag>
        ))}
      </ArtQualityMobileCategories>
    </>
  );
};

const ArtQuality = ({ artQuality, idx, onMouseEnter, onMouseLeave }) => {
  return (
    <StyledArtQuality
      {...ART_QUALITIES_STYLES[idx]}
      href={artQuality.link ? artQuality.link : null}
      as={artQuality.link ? 'a' : 'div'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Type>{artQuality.name}</Type>
      <Desc maxWidth={ART_QUALITIES_STYLES[idx]?.maxWidthDesc}>{artQuality.description}</Desc>
      <ArtQualityImg
        {...ART_QUALITIES_STYLES[idx]}
        imgUrl={artQuality.image?.formats?.large?.url}
        source={artQuality.source}
      />
    </StyledArtQuality>
  );
};

const ArtQualities = () => {
  const [artQualities, setArtQualities] = useState([]);

  const windowMouse = useMousePosition();

  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [hasLink, setHasLink] = useState(false);

  const onMouseEnter = (artQuality) => {
    setIsMessageVisible(true);
    setHasLink(!!artQuality.link);
  };
  const onMouseLeave = () => {
    setIsMessageVisible(false);
  };

  const animatedTagProps = useSpring({
    top: windowMouse.y || 0,
    left: windowMouse.x || 0,
    opacity: isMessageVisible ? 1 : 0,
    background: hasLink ? COLORS.BLUE : COLORS.WHITE,
    color: hasLink ? COLORS.WHITE : COLORS.BLACK,
  });

  useEffect(() => {
    contentFetch({
      pathname: '/art-qualities',
      setState: setArtQualities,
    });
  }, []);

  return (
    <>
      <Tag
        as={animated.div}
        style={{
          ...animatedTagProps,
        }}
      >
        {hasLink ? 'Click to explore' : 'Coming soon'}
      </Tag>
      <Container>
        <Title>Art Qualities</Title>
        {artQualities.map((artQuality, idx) => (
          <ArtQuality
            artQuality={artQuality}
            idx={idx}
            onMouseEnter={() => onMouseEnter(artQuality)}
            onMouseLeave={onMouseLeave}
          ></ArtQuality>
        ))}
        {artQualities.length ? <ArtQualitityMobile artQualities={artQualities} /> : null}
      </Container>
    </>
  );
};

export default ArtQualities;
