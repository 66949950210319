import styled from 'styled-components';
import { COLORS, BREAKPOINTS } from '../../utils/theme';

export const Background = styled.main`
  position: relative;
  background-color: ${(props) => props.color || COLORS.WHITE};
  padding-top: ${(props) => props.paddingTop && `${props.paddingTop}px`};
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding-top: 100px;
  } ;
`;
