import { TOP_HEIGHT, TITLE_HEIGHT } from './styles';

export const ART_QUALITIES_STYLES = [
  {
    maxWidth: 513,
    xy: [0, TOP_HEIGHT + TITLE_HEIGHT],
    maxWidthDesc: 380,
  },
  {
    fixTop: true,
    maxWidth: 513,
    xy: [514, TITLE_HEIGHT],
    maxWidthDesc: 380,
  },
  {
    maxWidth: 577,
    xy: [0, TOP_HEIGHT * 2 + TITLE_HEIGHT],
    maxWidthDesc: 380,
  },
  {
    maxWidth: 640,
    xy: [578, TOP_HEIGHT * 2 + TITLE_HEIGHT],
    maxWidthDesc: 380,
  },
  {
    maxWidth: 704,
    xy: [0, TOP_HEIGHT * 3 + TITLE_HEIGHT],
    maxWidthDesc: 380,
  },
  {
    maxWidth: 544,
    xy: [705, TOP_HEIGHT * 3 + TITLE_HEIGHT],
    maxWidthDesc: 380,
  },
  {
    maxWidth: 490,
    xy: [0, TOP_HEIGHT * 4 + TITLE_HEIGHT],
    maxWidthDesc: 380,
  },
  {
    maxWidth: 576,
    xy: [491, TOP_HEIGHT * 4 + TITLE_HEIGHT],
    maxWidthDesc: 380,
  },
  {
    maxWidth: 640,
    xy: [0, TOP_HEIGHT * 5 + TITLE_HEIGHT],
    maxWidthDesc: 380,
  },
];
