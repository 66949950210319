import styled from 'styled-components';
import { BREAKPOINTS, COLORS, H1, H5, P } from '../../../utils/theme';

import ArrowImg from './arrow.png';
import LineImg from './line.png';

const IMAGE_HOVER = {
  HEIGHT: 280,
  WIDTH: 350,
};

export const TOP_HEIGHT = 150;
export const TITLE_TOP = 110 + IMAGE_HOVER.HEIGHT / 2;
export const TITLE_HEIGHT = 20 + IMAGE_HOVER.HEIGHT / 2;
export const CONTAINER_HEIGHT = 900 + TITLE_HEIGHT;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: ${CONTAINER_HEIGHT}px;
  position: relative;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    height: initial;
  }
`;

export const Title = styled(H5)`
  color: ${COLORS.WHITE};
  transition: all 0.5s;
  position: absolute;
  left: 0;
  top: ${TITLE_TOP}px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    position: initial;
    margin-top: 120px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0.3px;
    padding: 0 20px;
  }
`;

export const Type = styled(H1)`
  color: ${COLORS.WHITE};
  padding-top: 30px;
  padding-bottom: 20px;
  transition: all 0.5s;
  position: relative;
  display: inline-block;
  &:before {
    transition: all 0.5s;
    content: '';
    background-image: url(${LineImg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    opacity: 0;

    @media (max-width: ${BREAKPOINTS.TABLET}px) {
      opacity: 1;
      right: initial;
      width: 50%;
      left: 15px;
      bottom: 12px;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}px) {
      left: 20px;
      bottom: 5px;
    }
  }
  &:after {
    transition: all 0.5s;
    content: '';
    background-image: url(${ArrowImg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    position: absolute;
    right: -60px;
    width: 43px;
    height: 29px;
    opacity: 0;

    @media (max-width: ${BREAKPOINTS.TABLET}px) {
      opacity: 1;
      right: -5px;
      top: -15px;
    }
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    padding: 38px 0 22px 22px;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: -0.44px;
    color: #fffbf7;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    font-size: 27px;
  }
`;

export const Desc = styled(P)`
  color: ${COLORS.WHITE};
  opacity: 0;
  transition: all 0.5s;
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px;`}

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    opacity: 0.9;
    padding-left: 20px;
    padding-right: 60px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.16px;
  }
`;

export const ArtQualityImg = styled.div`
  display: none;

  ${(props) =>
    props.imgUrl &&
    `
    transition: all .5s;
    z-index: 1;
    content: '';
    background-image: url(${props.imgUrl});
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
    position: absolute;
    width: ${IMAGE_HOVER.WIDTH}px;
    height: ${IMAGE_HOVER.HEIGHT}px;
    right: -${IMAGE_HOVER.WIDTH / 2}px;
    top: -${IMAGE_HOVER.HEIGHT / 2}px;
  `}

  ${(props) =>
    props.source &&
    `
    &:after {
      content: '${props.source}';
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: 14px;
      color: ${COLORS.WHITE};
    }
  `}
`;

export const ArtQuality = styled.a`
  border: 1px solid ${COLORS.WHITE};
  background-color: ${COLORS.BLACK};
  height: 232px;
  padding: 40px 64px;
  transition: all 0.5s;
  position: absolute;
  text-decoration: none;

  ${(props) => props.fixTop && `top: ${TITLE_HEIGHT}px !important;`}

  ${(props) =>
    props.xy &&
    `
        left: ${props.xy[0]}px;
        top: ${props.xy[1]}px;

        &:hover {
            top: ${props.xy[1] - TOP_HEIGHT}px;
            background-color: ${COLORS.RED};

            ${ArtQualityImg} {
              display: inline-block;
            }

            ${Title} {
                top: 0;
            }

            ${Type} {
                padding-top: 0;

                &:before,
                &:after {
                    opacity: 1;
                }
            }

            ${Desc} {
                opacity: .9;
            }
        }
    `}

    ${(props) => props.maxWidth && `width: ${props.maxWidth}px;`}

    @media (max-width: ${BREAKPOINTS.TABLET}px) {
    display: none;
  }
`;

export const ArtQualityMobileSelected = styled.a`
  display: none;

  text-decoration: none;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    display: flex;
    flex-direction: column;

    margin-left: 20px;
    width: 75%;
    height: 402px;
    background-color: ${COLORS.RED};
    position: relative;
  }
`;

export const ArtQualityMobileBackground = styled.div`
  display: none;
  pointer-events: none;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    display: initial;
    position: absolute;
    border-top: 1px solid ${COLORS.WHITE};
    border-right: 1px solid ${COLORS.WHITE};
    width: 20px;
    bottom: 0;

    &:nth-child(1) {
      top: 30px;
      right: -20px;
    }
    &:nth-child(2) {
      top: 54px;
      right: -40px;
    }
    &:nth-child(3) {
      top: 78px;
      right: -60px;
    }
    &:nth-child(4) {
      top: 102px;
      right: -80px;
    }
  }
`;

export const ArtQualityMobileCategories = styled.div`
  display: none;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    border-top: 1px solid ${COLORS.LIGHT_GREY};
    height: 75px;
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow-x: auto;
  }
`;

export const ArtQualityTag = styled(H5)`
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding: 0 20px;
  margin: 0 4px;
  background-color: ${COLORS.LIGHT_GREY};
  border: 1px solid ${COLORS.WHITE};
  color: ${COLORS.BLACK};
  border-radius: 18px;
  cursor: pointer;

  ${(props) =>
    props.selected &&
    `
      background-color: ${COLORS.BLACK};
      color: ${COLORS.WHITE};
  `}
`;

export const MobileTagContainer = styled.div`
  padding: 1.2rem;
  pointer-events: none;
`;

export const MobileTag = styled.span`
  background: ${(props) => (props.isLink ? COLORS.BLUE : COLORS.WHITE)};
  color: ${(props) => (props.isLink ? COLORS.WHITE : COLORS.BLACK)};
  display: inline-block;
  padding: 0.5rem 1.2rem;
  border-radius: 2rem;
  font-size: 0.8rem;
`;

export const Tag = styled.div`
  position: fixed;
  padding: 1rem 2rem;
  border-radius: 2rem;
  transform: translate(-50%, -50%);
  z-index: 10;
  pointer-events: none;
  white-space: nowrap;
`;
