import * as React from 'react';
import { useTransition } from 'react-spring';

import {
  Container,
  Title,
  Col,
  SliderContainer,
  SlideItem,
  SlideImage,
  SlideTitle,
  SlideP,
  SlideMedia,
  SlideControls,
  SlideNextContainer,
  SlideNextArrow,
  SlideNext,
  SlideStep,
} from './styles';

import { SectionContainer, Logo, MenuButton } from '../../HomeHeader/styles';

import Markdown from '../../Markdown';

import NextArrowImg from './next-arrow.png';

const Slide = ({ tag, img, title, desc, index, onClick, total, ...props }) => {
  return (
    <SlideItem {...props}>
      <Col>
        <SlideTitle>{title}</SlideTitle>
        <SlideP>
          <Markdown>{desc}</Markdown>
        </SlideP>
        <SlideControls>
          <SlideNextContainer onClick={onClick}>
            <SlideNextArrow src={NextArrowImg} />
            <SlideNext>Next</SlideNext>
          </SlideNextContainer>
          <SlideStep>
            {index + 1} of {total}
          </SlideStep>
        </SlideControls>
      </Col>
      <SlideMedia>
        <SlideImage src={img} />
      </SlideMedia>
    </SlideItem>
  );
};

const Slider = ({ items = [] }) => {
  const [index, set] = React.useState(0);
  const onClick = React.useCallback(() => set((state) => (state + 1) % items.length), [
    items.length,
  ]);
  const transitions = useTransition(index, (p) => p, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  });

  const pages = [...items].map((item) => ({ style, ...props }) => (
    <Slide
      {...props}
      total={items.length}
      style={style}
      img={item.media?.url}
      title={item.title}
      desc={item.description}
    />
  ));

  return (
    <>
      <>
        <SliderContainer>
          {transitions.map(({ item, props, key }) => {
            const Page = pages[item];
            return <Page key={key} style={props} onClick={onClick} index={index} />;
          })}
        </SliderContainer>
      </>
    </>
  );
};

const FeatureOngoingResearch = ({ ongoingResearches = [], menuIsShown }) => {
  return (
    <SectionContainer>
      <Logo visible />
      <MenuButton visible menuIsShown={menuIsShown} />
      <Title>Feature Ongoing Research</Title>
      <Container>{!!ongoingResearches.length && <Slider items={ongoingResearches} />}</Container>
    </SectionContainer>
  );
};

export default FeatureOngoingResearch;
