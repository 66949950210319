import styled from 'styled-components';
import { animated } from 'react-spring';

import { BREAKPOINTS, COLORS, Ps, H3 } from '../../../utils/theme';

const SECTION_HEIGHT = 585;

export const Title = styled(Ps)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.WHITE};
  box-shadow: inset 0px 32px 64px 0px rgba(0, 0, 0, 0.05);
  padding: 30px 0;
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0.3px;
  }
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.BACKGROUND_LIGHT};
  box-shadow: inset 0px 32px 64px 0px rgba(0, 0, 0, 0.05);
  padding: 60px 0 60px 86px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    padding: 20px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

export const SliderContainer = styled.div`
  display: flex;
`;

export const SlideItem = styled(animated.div)`
  position: relative;
  display: flex;
  flex-direction: row;
  height: ${SECTION_HEIGHT}px;
  width: 100%;
  min-width: 100%;
  will-change: transform, opacity;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    flex-direction: column-reverse;
    height: ${SECTION_HEIGHT + 60}px;
  }
`;

export const SlideMedia = styled.div`
  width: 780px;
  height: ${SECTION_HEIGHT}px;
  margin-left: 100px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    margin: 0;
    width: 100%;
    height: 188px;
  }
`;

export const SlideImage = styled.img`
  height: ${SECTION_HEIGHT}px;
  object-fit: cover;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    height: 188px;
    width: 100%;
  }
`;

export const SlideTitle = styled(H3)`
  margin-top: 40px;
  margin-bottom: 30px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    margin-top: 20px;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
`;

export const SlideP = styled.div`
  color: ${COLORS.BLACK};
  font-size: 20px;
  font-weight: normal;
  opacity: 0.9;
  line-height: 32px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.16px;
  }
`;

export const SlideControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    margin-bottom: 40px;
  }
`;

export const SlideNextContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SlideNextArrow = styled.img`
  width: 14px;
  height: 26px;
  margin-right: 20px;
`;

export const SlideNext = styled.h6`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #b0adab;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.3px;
  }
`;

export const SlideStep = styled(SlideNext)``;
